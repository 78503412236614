import { Component, Input, OnInit } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
})
export class ActionModalComponent implements OnInit {

  faCheckCircle = faCheckCircle;
  @Input() type: string;

  constructor(
    private modalService: ModalController,
    public toolService: ToolService
  ) { }

  ngOnInit() {/* empty */}

  close = () => {
    this.modalService.dismiss();
  }
}
