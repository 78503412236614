import { Component, OnInit } from '@angular/core';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { ModalController, NavController } from '@ionic/angular';


@Component({
    selector: 'app-itinerary-customer-info',
    templateUrl: './itinerary-customer-info.component.html',
    styleUrls: ['./itinerary-customer-info.component.scss'],
})
export class ItineraryCustomerInfoComponent implements OnInit {

    faInfoCircle = faInfoCircle;
    
    constructor(
        private modalController: ModalController,
        private navCtrl: NavController
    ) {}

    ngOnInit() {/* empty */}

    ctaClick() {
        this.modalController.dismiss({ success: true });
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
