import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-update-agreement',
  templateUrl: './update-agreement.component.html',
  styleUrls: ['./update-agreement.component.css']
})
export class UpdateAgreementComponent implements OnInit {

  constructor(
    private modalCtrl: ModalController
  ) { }

  @Input() id: string;
  @Input() message: string;

  d = new Date();

  ngOnInit() {/* empty */}

  dismiss() {
    this.modalCtrl.dismiss({agreed: true});
  }

}
