import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/core/services/form/form.service';
import { PaymentService } from '@app/core/services/payment/payment.service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { AlertController, ModalController } from '@ionic/angular';
import { from, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Component({
    selector: 'app-stripe-modal',
    templateUrl: './stripe-modal.component.html',
    styleUrls: ['./stripe-modal.component.scss'],
})
export class PaymentStripeModalComponent implements OnInit {
    @Input() applicationId: any;
    @Input() isEnvoy = false;
    @Input() applicationName?: string;

    constructor(
        private toolService: ToolService,
        private paymentService: PaymentService,
        private modalCtrl: ModalController,
        private formService: FormService,
        private alertCtrl: AlertController
    ) {}

    payment: any = {
        ready: false,
        paying: false,
    };

    payment_form: FormGroup;
    paymentForm_subscription = this.formService.paymentForm().subscribe((form: FormGroup) => (this.payment_form = form));
    stripe: any;
    cardNumber: any;
    cardCvc: any;
    cardExpiry: any;

    newContent: any;
    content = this.toolService.content;

    ngOnInit() {
        // all our requests
        this.toolService.content
            .pipe(
                flatMap((content: any) => {
                    this.newContent = content;
                    this.payment.ready = true;
                    if (this.isEnvoy === true) {
                        return this.paymentService.envoyAmount(this.applicationId);
                    } else {
                        return this.paymentService.insuranceAmount(this.applicationId);
                    }
                }),
                flatMap((amount: any) => {
                    // get amount due
                    this.payment.buttonText = `Pay Now $${amount.amount * 0.01} USD`;
                    return this.paymentService.stripeEnv();
                })
            )
            .subscribe(
                (response: any) => {
                    // get stripe variables
                    this.stripe = Stripe(response.key);
                    const elements = this.stripe.elements();
                    const elementStyles = {
                        base: {
                            color: '#32325d',
                            fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
                            fontSize: '16px',
                            textAlign: 'center',
                            fontSmoothing: 'antialiased',

                            ':focus': {
                                color: '#424770',
                            },

                            '::placeholder': {
                                color: '#9BACC8',
                            },

                            ':focus::placeholder': {
                                color: '#CFD7DF',
                            },
                        },
                        invalid: {
                            color: '#FA755A',
                            ':focus': {
                                color: '#FA755A',
                            },
                            '::placeholder': {
                                color: '#FFCCA5',
                            },
                        },
                    };

                    const elementClasses = {
                        focus: 'focus',
                        empty: 'empty',
                        invalid: 'invalid',
                    };

                    this.cardNumber = elements.create('cardNumber', {
                        style: elementStyles,
                        classes: elementClasses,
                    });
                    this.cardNumber.mount('#card-number');

                    this.cardExpiry = elements.create('cardExpiry', {
                        style: elementStyles,
                        classes: elementClasses,
                    });
                    this.cardExpiry.mount('#card-expiry');

                    this.cardCvc = elements.create('cardCvc', {
                        style: elementStyles,
                        classes: elementClasses,
                    });
                    this.cardCvc.mount('#card-cvc');

                    this.cardNumber.addEventListener('change', (event: any) => {
                        const displayError = document.getElementById('card-errors');
                        displayError.textContent = event.error ? event.error.message : '';
                    });

                    this.cardExpiry.addEventListener('change', (event: any) => {
                        const displayError = document.getElementById('card-errors');
                        displayError.textContent = event.error ? event.error.message : '';
                    });

                    this.cardCvc.addEventListener('change', (event: any) => {
                        const displayError = document.getElementById('card-errors');
                        displayError.textContent = event.error ? event.error.message : '';
                    });
                },
                (err: Error) => {
                    console.log('stripe component err: ', err);
                    // this.alertService.error(err.message);
                },
                () => {
                    // console.log(this.payment);
                }
            );
    }

    close() {
        this.modalCtrl.dismiss({
            purchased: false,
        });
    }

    dismissPaid() {
        this.modalCtrl.dismiss({
            purchased: true,
        });
    }

    submit = () => {
        this.payment.paying = true;
        from(this.createSrc())
            .pipe(
                flatMap((result: any) => {
                    if (result.error) {
                        this.payment.paying = false;
                        throw new Error(`${result.error.message}`);
                    } else {
                        if (this.isEnvoy) {
                            return this.paymentService.envoyCharge(this.applicationId, result.source.id);
                        } else {
                            return this.paymentService.insuranceCharge(
                                this.applicationId, 
                                result.source.id,
                                this.payment_form.value.email
                            );
                        }
                    }
                })
            )
            .subscribe(
                (response: any) => {
                    // console.log('response from stripe', response);
                    if (response.type === 'success') {
                        this.payment.paying = false;
                        this.dismissPaid();
                    } else if (response.type === 'warning') {
                        this.alertWarning(response.message);
                    }
                },
                (error: any) => {
                    this.payment.paying = false;
                    // this.alertService.basicAlert('Attention', 'Payment', `${error.message}`, ['Ok']);
                }
            );
    };

    createSrc(): Promise<any> {
        // const dirtyPhoneNumber: string = this.payment_form.value['phone'];
        // const safePhoneNumber: string = '+' + dirtyPhoneNumber.match(regexTools.numberReg).join('');

        return this.stripe.createSource(this.cardNumber, {
            owner: {
                name: this.payment_form.value.name,
                email: this.payment_form.value.email,
                address: {
                    line1: this.payment_form.value.address_line1,
                    line2: null,
                    city: this.payment_form.value.address_city,
                    state: this.payment_form.value.address_state,
                    postal_code: this.payment_form.value.address_zip,
                    country: this.payment_form.value.country,
                },
            },
        });
    }

    hasError(event: any) {
        // console.log('phone has error: ', event);
    }

    getNumber(event: any) {
        this.payment_form.patchValue({
            phone: event,
        });
    }

    async alertWarning(message: any) {
        const alert = await this.alertCtrl.create({
            header: 'Alert',
            subHeader: 'Payment',
            message: `${message}`,
            buttons: [
                {
                    text: 'Ok',
                    handler: () => {
                        this.modalCtrl.dismiss();
                    },
                },
            ],
        });
        await alert.present();
    }

    telInputObject(obj: any) {/* empty */}

    onCountryChange(event: any) {/* empty */}
}
