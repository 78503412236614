import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DocumentService } from '@app/core/services/document/document.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { UserService } from '@app/core/services/user/user.service';
import { WarningService } from '@app/core/services/warning/warning.service';
import { environment } from '@environment/environment';
import { faDesktop, faFileCheck, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { AlertController, ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription } from 'rxjs';
import { ApplicationService } from '@app/core/services/application/application.service';
import { QueryService } from '@app/core/graphql/query/query.service';
import { SendEventActionsService } from '@app/core/services/events/sendEventActions.service';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { MutationService } from '@app/core/graphql/mutation/mutation.service';

@Component({
    selector: 'app-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.scss'],
})
export class UploadComponent implements OnInit {
    @ViewChild('downloadZipLink', { static: true })
    private downloadZipLink: ElementRef;

    // the users profile to update
    @Input() profile: any;
    // the item# matching what column on the user table
    @Input() uploadItem: string;
    // the short string key amo uses to identify what item#
    @Input() uploadIdentity: string;
    // the id of the application if uploading application document
    @Input() application_Id?: string;
    // url of application document to download
    @Input() url?: string;
    // url of application document to download
    @Input() fileName?: string;
    // id for Application Requirement Map table
    @Input() applicationRequirement_Id?: string;
    // list of application ids from chain
    @Input() application_IdList?: string[];
    // level associations to requirement uploaded
    @Input() levelParams?: any;
    // requires user to upload a document
    @Input() requiredSubmission: boolean = true;

    // loading bar value
    progress = 0;
    prog = { percent: 0, status: '' };
    subscription: Subscription;
    upLoad: any = {};
    uploadResponse: any;
    color: string;
    filename: string;
    uploading: any;
    submitted: any;
    disable = false;

    generalDocumentsIdentityArray = ['cv', 'schoolrecords', 'toefl', 'passport', 'visaproof', 'letterofrecc', 'healthinsurance'];

    icons: any = {
        upload: faUpload,
        desktop: faDesktop,
        acknowledge: faFileCheck,
    };

    immuneIdentityArray = ['immune'];
    additionalIdentity = ['application', 'malpractice'];
    existingImmuneDocuments: any = [];
    existingRequirmentDocuments: any = [];
    existingHealthInsuranceDocuments: any = [];

    constructor(
        private documentService: DocumentService,
        private userService: UserService,
        private alertCtrl: AlertController,
        private modalCtrl: ModalController,
        private toastService: ToastService,
        private cookie: CookieService,
        private warning: WarningService,
        private applicationService: ApplicationService,
        private queryService: QueryService,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private sendEventActionService: SendEventActionsService,
        private mutationService: MutationService
    ) {}

    sendRumAction(label, context = {}) {
        this.sendEventActionService.sendEventActions({
            eventAction: `document_upload_${label}`,
            eventCategory: 'ApplicationDetail',
            ddAction: `document_upload_${label}`,
            ddContext: {
                ctxUploadItem: this.uploadItem,
                ...context,
            },
            customDimensions: {},
        });
    }

    async ngOnInit() {
        this.prog = this.documentService.progress;
        this.prog.status = '';
        this.prog.percent = 0;
        this.sendRumAction('showModal');

        if (!this.cookie.check(`${environment.ENGLISH_ONLY_UPLOAD}`)) {
            // this.alert();
        }
        this.upLoad.title =
            this.uploadIdentity === 'cv'
                ? 'Curriculum Vitae'
                : this.uploadIdentity === 'schoolrecords'
                ? 'Letter from Dean or Diploma'
                : this.uploadIdentity === 'toefl'
                ? 'English Proficiency Test Results'
                : this.uploadIdentity === 'passport'
                ? 'Passport'
                : this.uploadIdentity === 'letterofrecc'
                ? 'Letter of Recommendation'
                : this.uploadIdentity === 'visaproof'
                ? 'Proof of Visa'
                : this.uploadIdentity === 'healthinsurance'
                ? 'Health Insurance'
                : this.uploadIdentity === 'malpractice'
                ? 'Malpractice Insurance'
                : this.uploadIdentity === 'immune'
                ? 'Immunization Records'
                : this.uploadIdentity === 'application'
                ? 'Application Documents'
                : 'documents';
        if (typeof this.url === 'undefined' || this.url === null) {
            this.url = 'none';
        }

        if (this.uploadIdentity === 'immune') {
            await this.documentService.immunizationDocuments(this.profile.id).subscribe(
                (data) => {
                    this.existingImmuneDocuments = data;
                },
                (error) => {
                    this.existingImmuneDocuments = [];
                }
            );
        } else if (this.additionalIdentity.indexOf(this.uploadIdentity) !== -1) {
            if (this.applicationRequirement_Id && this.uploadIdentity !== 'malpractice') {
                //console.log(this.uploadIdentity, this.profile.applicationid);
                await this.documentService.requirementDocuments(this.profile.id).subscribe(
                    (data) => {
                        this.existingRequirmentDocuments = data;
                    },
                    (error) => {
                        this.existingRequirmentDocuments = [];
                    }
                );
            } else {
                this.existingRequirmentDocuments = [];
            }
        } else if (this.generalDocumentsIdentityArray.indexOf(this.uploadIdentity) !== -1) {
            if (this.uploadIdentity == 'healthinsurance') {
                //console.log(this.uploadIdentity, this.profile.applicationid);
                await this.queryService.applicationHealthinsuranceDocuments(this.profile.id || this.profile.applicationid).subscribe(
                    (response: any) => {
                        if (response.data && response.data.applicationHealthinsuranceDocument.documents) {
                            this.existingHealthInsuranceDocuments = response.data.applicationHealthinsuranceDocument.documents;
                        } else {
                            this.existingHealthInsuranceDocuments = [];
                        }
                        //console.log(response);
                    },
                    (error) => {
                        this.existingHealthInsuranceDocuments = [];
                    }
                );
            } else {
                this.existingHealthInsuranceDocuments = [];
            }
        } else {
            this.existingImmuneDocuments = [];
            this.existingRequirmentDocuments = [];
            this.existingHealthInsuranceDocuments = [];
        }

        //console.log(this.uploadIdentity);
    }

    /**
     *
     *
     * item 1 = CV ['cv']
     * item 2 = Passport ['passport']
     * item 3 = malpractice insurance ['malpractice'] NOT USED MORE , use gql query applicationMalpracticeDocuments
     * item 4 = school records / letter dean / diploma ['schoolrecords']
     * item 5 = immunizations - Not used anymore, using getMyImmunizationDocs endpoint meow ['immune']
     * item 6 = letter of recommendation ['letterofrecc']
     * item 7 = proof visa ['visaproof']
     * item 8 = health insurance ['healthinsurance']
     * item 9 = records - No Item 9!! []
     * item 10 = toefl ['toefl']
     *
     *
     */

    download() {
        let program_id;
        let application_id;
        if (this.applicationRequirement_Id) {
            this.applicationService
                .downloadRequirementDocument({
                    applicationrequirementid: this.applicationRequirement_Id,
                })
                .subscribe((res: any) => {
                    if (res.url === 'undefined') {
                        // eslint-disable-next-line max-len
                        this.warning.create(
                            'Sorry, we couldn\'t find that document.<br />Please contact us at <a href="mailto:support@amopportunities.org">support@amopportunities.org</a'
                        );
                    } else {
                        this.openUrl(res.url);
                    }
                });
        } else {
            if (typeof this.profile.programid !== 'undefined') {
                program_id = this.profile.programid;
            } else {
                program_id = this.profile.application.programid;
            }
            if (typeof this.profile.applicationid !== 'undefined') {
                application_id = this.profile.applicationid;
            } else {
                application_id = this.profile.application.id;
            }
            // check if url is a link to website or aws prefix url
            if (this.checkurl(this.url)) {
                this.openUrl(this.url);
                return;
            }
            const userpacket = {
                userid: this.profile.id,
                key: this.url,
                programid: program_id,
            };
            /**
             * takes program id, document key to complete aws url, application id, and the user id
             */
            this.applicationService.viewadditionalrequirement(application_id, userpacket).subscribe((res: any) => {
                if (res.url === 'undefined') {
                    // eslint-disable-next-line max-len
                    this.warning.create(
                        'Sorry, we couldn\'t find that document.<br />Please contact us at <a href="mailto:support@amopportunities.org">support@amopportunities.org</a'
                    );
                } else {
                    this.openUrl(res.redirect);
                }
            });
        }
    }

    openUrl(url: string) {
        window.open(url, '_blank');
    }

    checkurl(s) {
        const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        return regexp.test(s);
    }

    uploadTimer = () => {
        const timer = interval(1000);
        this.subscription = timer.subscribe((x) => {
            this.progress += 0.1;
            if (x === 10) {
                this.stopUploadTimer();
            }
        });
    };

    stopUploadTimer = () => {
        try {
            this.subscription.unsubscribe();
        } catch (e) {
            /* empty */
        }
    };

    ionViewDidLeave() {
        // this.stopProgress();
        this.stopUploadTimer();
    }

    upload = (files: FileList) => {
        this.disable = true;
        this.sendRumAction('begin', { files });

        if (files.length > 1) {
            this.sendRumAction('invalid_notOneFile');
            this.warning.create('Please upload one document at a time.');
            this.disable = false;
            return;
        }

        const file = files[0];
        if (file == null) {
            this.sendRumAction('invalid_noFile');
            this.warning.create('No file found. Please upload either a PDF or JPEG format file.');
            this.disable = false;
            return;
        }
        if (
            (String(file.type) !== 'application/pdf' && String(file.type) !== 'image/jpeg' && String(file.name).includes('.png')) ||
            (String(file.name).includes('.PNG') && !file.type)
        ) {
            this.sendRumAction('invalid_wrongFiletype');
            this.warning.create('Please upload a file that either ends with .pdf or .jpeg.');
            this.disable = false;
            return;
        }
        if (String(file.type).toLowerCase().includes('heic')) {
            this.sendRumAction('invalid_wrongFiletype heic');
            this.warning.create('Please upload a file that either ends with .pdf or .jpeg.');
            this.disable = false;
            return;
        }
        if (file.size > 4500000) {
            this.sendRumAction('invalid_tooLarge');
            this.warning.create('All uploads must be 4MB or less.');
            this.disable = false;
            return;
        }
        // this.uploadTimer();
        // this.prog = this.documentService.progress;
        const filenameString = String(file.name);
        const noSpaces = filenameString.replace(/ /g, '_');
        let fileName = noSpaces.replace(/[`~!@#$%^&*()|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

        this.filename = fileName;
        if (this.generalDocumentsIdentityArray.indexOf(this.uploadIdentity) !== -1) {
            // user is uploading general documents
            let api;
            if (this.uploadIdentity == 'healthinsurance') {
                api = this.documentService.handleHealthInsuranceUpload(
                    files[0],
                    this.uploadIdentity,
                    this.profile,
                    this.existingHealthInsuranceDocuments || []
                );
            } else {
                api = this.documentService.handleGeneralDocumentsUpload(files[0], this.uploadIdentity, this.profile);
            }
            api.subscribe(
                (response: any) => {
                    const identityLabel = this.uploadIdentity == 'healthinsurance' ? 'handleHealthInsuranceUpload' : 'handleGeneralDocumentsUpload';
                    this.sendRumAction('response', { response, identityLabel });
                    this.uploadResponse = response;
                    try {
                        var resObj = JSON.parse(response);
                        if (resObj && typeof resObj === 'object' && 'key' in resObj) {
                            this.sendRumAction('responseHasKey');
                            this.updateProfile(response, fileName);
                        } else {
                            this.sendRumAction('responseKeyMissing');
                            this.failedUploadMessageDismiss(identityLabel);
                        }
                    } catch (e) {
                        this.sendRumAction('responseParseError');
                        this.failedUploadMessageDismiss(identityLabel);
                    }
                },
                (error: any) => {
                    const identityLabel = this.uploadIdentity == 'healthinsurance' ? 'handleHealthInsuranceUpload' : 'handleGeneralDocumentsUpload';
                    this.sendRumAction('error', { error, identityLabel });
                    this.failedUploadMessageDismiss(identityLabel);
                }
            );

            // TEST DATA
        } else if (this.immuneIdentityArray.indexOf(this.uploadIdentity) !== -1) {
            // user is uploading immunizations
            this.documentService.handleImmunizationUpload(files, this.uploadIdentity, this.profile.id, this.existingImmuneDocuments).subscribe(
                async (response: any) => {
                    this.uploadResponse = response;
                    if (response == 'success') {
                        this.successUpload();
                        setTimeout(() => location.reload(), 1500); //reload
                        await this.modalCtrl.dismiss({
                            result: 'immune',
                        });
                    } else {
                        this.failedUploadMessageDismiss('immune');
                    }
                },
                async (error: Error) => {
                    this.failedUploadMessageDismiss('immune');
                }
            );
        } else if (this.additionalIdentity.indexOf(this.uploadIdentity) !== -1) {
            if (this.applicationRequirement_Id && this.uploadIdentity !== 'malpractice' && this.requiredSubmission) {
                this.documentService
                    .handleApplicationRequirementMapDocumentsUpload(
                        files,
                        this.fileName,
                        this.uploadIdentity,
                        this.profile.id,
                        this.application_Id,
                        this.applicationRequirement_Id,
                        this.application_IdList,
                        {
                            levelType: this.levelParams.levelType,
                            levelId: this.levelParams.levelId,
                        },
                        this.existingRequirmentDocuments
                    )
                    .then(async (response) => {
                        this.uploadResponse = response;
                        if (response) {
                            if (response.success == true) {
                                console.log('response', response);
                                const requirementsItems = {
                                    submittedValues: response.parsedXML,
                                };
                                this.applicationService
                                    .updateSubmittedRequirement(
                                        this.application_Id,
                                        this.application_IdList,
                                        this.applicationRequirement_Id,
                                        requirementsItems,
                                        null
                                    )
                                    .subscribe({
                                        next: (response) => {
                                            this.successUpload();
                                            this.successDismiss();
                                        },
                                        error: async (err) => {
                                            this.failedUploadMessageDismiss('handleApplicationRequirementMapDocumentsUpload');
                                        },
                                    });
                            } else {
                                this.failedUploadMessageDismiss('handleApplicationRequirementMapDocumentsUpload');
                            }
                        }
                    })
                    .catch(async (err) => {
                        this.failedUploadMessageDismiss('handleApplicationRequirementMapDocumentsUpload');
                    });
            } else if (this.applicationRequirement_Id && this.uploadIdentity !== 'malpractice' && !this.requiredSubmission) {
                this.applicationService
                    .updateSubmittedRequirement(this.application_Id, this.application_IdList, this.applicationRequirement_Id, null, {
                        submittedValues: { acknowledged: true },
                    })
                    .subscribe({
                        next: (response) => {
                            this.successUpload();
                            this.successDismiss();
                        },
                        error: async (err) => {
                            this.failedUploadMessageDismiss('handleApplicationRequirementMapDocumentsUpload');
                        },
                    });
            } else if (this.uploadIdentity === 'malpractice') {
                this.documentService
                    .handleApplicationMalpracticeDocumentsUpload(files, this.fileName, this.uploadIdentity, this.profile.id, this.application_Id)
                    .then(async (response) => {
                        this.uploadResponse = response;
                        if (response) {
                            if (response.success == true) {
                                const requirementsItems = {
                                    submittedValues: response.parsedXML,
                                };
                                this.applicationService.updateSubmittedRequirement(this.application_Id, [], 'malpractice', requirementsItems, null).subscribe({
                                    next: async (response) => {
                                        this.successUpload();
                                        setTimeout(() => location.reload(), 1500); //reload
                                        await this.modalCtrl.dismiss({
                                            result: 'handleApplicationMalpracticeDocumentsUpload',
                                        });
                                    },
                                    error: async (err) => {
                                        this.failedUploadMessageDismiss('handleApplicationMalpracticeDocumentsUpload');
                                    },
                                });
                            } else {
                                this.failedUploadMessageDismiss('handleApplicationMalpracticeDocumentsUpload');
                            }
                        }
                    })
                    .catch(async (err) => {
                        this.failedUploadMessageDismiss('handleApplicationMalpracticeDocumentsUpload');
                    });
            }
        } else {
            this.failedUploadMessageDismiss('handleApplicationMalpracticeDocumentsUpload');
        }
    };

    acknowledgmentInit() {
        this.applicationService
            .updateSubmittedRequirement(this.application_Id, this.application_IdList, this.applicationRequirement_Id, null, {
                submittedValues: { acknowledged: true },
            })
            .subscribe({
                next: (response) => {
                    this.successUpload();
                    this.successDismiss();
                },
                error: async (err) => {
                    this.failedUploadMessageDismiss('handleApplicationRequirementMapDocumentsUpload');
                },
            });
    }

    updateProfile = (doc: any, fileName: string) => {
        console.log('updateProfile begin');
        this.prog.status = 'updating profile...';
        const document = JSON.parse(doc);
        if (this.uploadIdentity === 'cv') {
            const data = {
                id: this.profile.id,
                item1: document.key,
                item1name: fileName,
                documentuploadsat: new Date().toISOString(),
            };

            // if(this.profile.item1name == null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                // console.log(res);
                this.successDismiss();
            });
            // this.successDismiss();
        }
        if (this.uploadIdentity === 'schoolrecords') {
            const data = {
                id: this.profile.id,
                item4: document.key,
                item4name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item4name ==  null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                // console.log(res);
                this.successDismiss();
            });
        }
        // check back for US citizens and use Drivers license
        if (this.uploadIdentity === 'passport') {
            const data = {
                id: this.profile.id,
                item2: document.key,
                item2name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item2name ==  null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                // console.log(res);
                this.successDismiss();
            });
        }
        if (this.uploadIdentity === 'visaproof') {
            const data = {
                id: this.profile.id,
                item7: document.key,
                item7name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item7name ==  null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                //  console.log(res);
                this.successDismiss();
            });
        }
        if (this.uploadIdentity === 'healthinsurance') {
            // const data = {
            //     id: this.profile.id,
            //     item8: document.key,
            //     item8name: document.from ? document.fileName : fileName,
            //     documentuploadsat: new Date().toISOString(),
            // };
            // //console.log(data);
            // // if(this.profile.item8name ==  null) this.events.publish('update:progress', (1));
            // this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
            //     // console.log(res);
            //     this.successDismiss();
            // });

            this.successDismiss();
        }
        if (this.uploadIdentity === 'letterofrecc') {
            const data = {
                id: this.profile.id,
                item6: document.key,
                item6name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item6name ==  null) this.events.publish('update:progress', (4));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                // console.log(res);
                this.successDismiss();
            });
        }
        if (this.uploadIdentity === 'toefl') {
            const data = {
                id: this.profile.id,
                item10: document.key,
                item10name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item10name ==  null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                // console.log(res);
                this.successDismiss();
            });
        }
        if (this.uploadIdentity === 'malpractice') {
            const data = {
                id: this.profile.id,
                item3: document.key,
                item3name: fileName,
                documentuploadsat: new Date().toISOString(),
            };
            // if(this.profile.item10name ==  null) this.events.publish('update:progress', (11));
            this.userService.easyUpdateProfile(data, this.profile.id).subscribe((res: any) => {
                //  console.log(res);
                this.successDismiss();
            });
        }
    };

    dismiss() {
        this.modalCtrl.dismiss({
            result: false,
        });
    }

    async successDismiss() {
        console.log('successDismiss');
        this.prog.status = '';
        this.prog.percent = 0;
        await this.modalCtrl.dismiss({
            result: true,
            filename: this.filename,
            key: this.uploadIdentity,
        });
    }

    failedUpload() {
        this.toastService.create({
            message: 'Upload failed.',
            duration: 3000,
            position: 'top',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
            closeButtonText: 'Done',
            color: 'danger',
        });
        this.disable = false;
    }

    async failedUploadMessageDismiss(type: string, res: any = null) {
        await this.modalCtrl.dismiss({
            result: 'error', //type //"immune"
        });
        this.failedUpload();
        this.subscription.unsubscribe();
        this.progress = 0;
        //console.log(`failed ${ type } upload: else`, res);
    }

    successUpload() {
        this.toastService.create({
            message: 'Document uploaded.',
            duration: 3000,
            position: 'top',
            buttons: [
                {
                    text: 'Close',
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    },
                },
            ],
            closeButtonText: 'Done',
            color: 'primary',
        });
    }

    // download immunizations form
    downloadImmuneForm() {
        this.documentService.downloadImmunizationForm().subscribe((res: any) => {
            const base64str = res.Body.data;
            const binary = atob(base64str.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const link = this.downloadZipLink.nativeElement;
            link.href = url;
            link.download = 'AMOpportunities_Certificate_of_Immunization.pdf';
            link.click();
            window.URL.revokeObjectURL(url);
        });
    }
}
