import { Component, Input, OnChanges } from '@angular/core';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalService } from '@core/services/modal/modal.service';
import { faCalendarDay } from '@fortawesome/pro-regular-svg-icons';
import { userSelectors } from '@app/core/ngrx/selectors';
import { select, Store } from '@ngrx/store';
import { State } from '@app/core/ngrx/reducers';

// returns true if ashleys name is in the application coach property
const coachAshley = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('ashley') !== -1;
};

// returns true if wanhs name is in the application coach property
const coachWanh = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('wanh') !== -1;
};

// returns true if wanhs name is in the application coach property
const coachMia = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('mia') !== -1;
};

// returns true if wanhs name is in the application coach property
const coachKelli = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('kelli') !== -1;
};

// returns true if brooks name is in the application coach property
const coachBrook = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('brook') !== -1;
};

// returns true if megha name is in the application coach property
const coachMegha = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('megha') !== -1;
};

// returns true if justin name is in the application coach property
const coachJustin = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('justin') !== -1;
};

// returns true if chris name is in the application coach property
const coachChris = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('chris') !== -1;
};

// returns true if grace name is in the application coach property
const coachGrace = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('grace') !== -1;
};

// returns true if grace name is in the application coach property
const coachCaleb = (coach: string): boolean => {
    return coach.toLowerCase().indexOf('caleb') !== -1;
};

@Component({
    selector: 'app-advisor-sidebar',
    templateUrl: './advisor-sidebar.component.html',
    styleUrls: ['./advisor-sidebar.component.scss'],
})
export class SidebarAdvisorComponent implements OnChanges {
    textcontent: any;

    @Input() application?: any;
    // ashley, wanh, sam
    @Input() advisor: boolean;
    @Input() coach: boolean;

    amo: any = {};

    data = {
        title: '',
        header: '',
        topMessage: '',
        bottomMessage: '',
        icon: '',
        color: '',
        lines: 2,
        class: 'sidebar-modal-sm',
    };

    icons: any = {
        calendar: faCalendarDay,
    };

    USER_EMAIL$ = this.store.select(userSelectors.getUserEmail);
    USER_EMAIL: string;
    constructor(public toolService: ToolService, private modalService: ModalService, private store: Store<State>) {
        this.toolService.content.subscribe((textcontent: any) => (this.textcontent = textcontent));
    }

    ngOnChanges() {
        const application = this.application || {};
        if (this.advisor) {
            this.amo.ashley = false;
            this.amo.mia = false;
            this.amo.wanh = false;
            this.amo.kelli = false;
            this.amo.brook = false;
            this.amo.megha = false;
            this.amo.justin = false;
            this.amo.chris = false;
            this.amo.grace = false;
            this.amo.caleb = false;
        } else if (!application.coach && !this.advisor) {
            this.amo.unassignedcoach = true;
        } else if (application.coach.indexOf('test') !== -1) {
            this.amo.ashley = true;
        } else {
            this.amo.ashley = coachAshley(application.coach);
            this.amo.wanh = coachWanh(application.coach);
            this.amo.mia = coachMia(application.coach);
            this.amo.kelli = coachKelli(application.coach);
            this.amo.brook = coachBrook(application.coach);
            this.amo.megha = coachMegha(application.coach);
            this.amo.justin = coachJustin(application.coach);
            this.amo.chris = coachChris(application.coach);
            this.amo.grace = coachGrace(application.coach);
            this.amo.caleb = coachCaleb(application.coach);
        }

        this.USER_EMAIL$.subscribe((useremail: string) => (this.USER_EMAIL = useremail));
    }

    openInformationModal() {
        this.data.topMessage = this.textcontent.shared.advisorSidebar.modalContent.topContent;
        this.data.bottomMessage = `${this.textcontent.shared.advisorSidebar.modalContent.bottomContent}` + `${this.textcontent.basic.phone.main}` + `.`;
        this.modalService.basicInfoAlert(this.data).then((response: any) => {
            // console.log(response);
        });
    }
}
