<ion-header>
    <ion-item class="modal-header-color">
        <ion-label>
            <ion-text>
                <h2 class="modal-title ion-text-center ion-text-wrap">
                    <fa-icon [icon]="icons.upload" slot="start" class="fa-line-padding"></fa-icon>
                    {{
                        uploadIdentity === 'application' && !requiredSubmission && url === 'none'
                            ? 'Review'
                            : uploadIdentity === 'application' && !requiredSubmission && url !== 'none'
                            ? 'Download'
                            : 'Upload'
                    }}
                    your {{ upLoad?.title }}.
                </h2>
            </ion-text>
        </ion-label>
        <ion-button slot="end" class="close" type="button" fill="clear" (click)="dismiss()">
            <ion-icon slot="icon-only" color="danger" name="close" size="medium"></ion-icon>
        </ion-button>
    </ion-item>
</ion-header>
<ion-grid class="modal-content ion-no-padding">
    <a class="download-zip-link" #downloadZipLink></a>
    <ion-col size="12">
        <ion-item class="ion-text-center" lines="none">
            <ion-label class="section-title ion-text-wrap"> Checklist </ion-label>
        </ion-item>
        <!-- application requirement and has downloadable document -->
        <ion-list *ngIf="uploadIdentity === 'application' && url !== 'none'">
            <ion-item class="upload-instructions-container" button detail (click)="download()" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            Download this document{{ requiredSubmission ? 'and fill it out' : '' }}. If you are not able to download the document, check your
                            browser's settings to allow popups for this site.
                        </p>
                    </ion-text>
                </ion-label>
                <ion-button slot="end" class="download-button"> Download </ion-button>
            </ion-item>
            <ion-item *ngIf="requiredSubmission" class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            All documents must be under 4MB in size and in either PDF or JPEG format. Important: Please make sure all documents are in english
                            or have an english translation. Otherwise, they will be rejected by the Program.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item *ngIf="!requiredSubmission" class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you have download these required documents and read them, click "Acknowledged".</p>
                    </ion-text>
                    <!-- <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" /> -->
                    <ion-button class="ion-text-center" expand="block" size="large" (click)="acknowledgmentInit()">
                        <fa-icon [icon]="icons.acknowledge" slot="start"></fa-icon>
                        &nbsp;Acknowledged
                    </ion-button>
                </ion-label>
            </ion-item>
            <ion-item *ngIf="requiredSubmission" class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 3</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you have reviewed your documents, you may upload them here.</p>
                    </ion-text>
                    <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" />
                    <ion-button class="ion-text-center" expand="block" size="large">
                        <fa-icon [icon]="icons.desktop" slot="start"></fa-icon>
                        &nbsp;Browse Your Computer
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <!-- profile document and has downloadable document -->
        <ion-list *ngIf="uploadIdentity !== 'application' && uploadIdentity !== 'immune' && url !== 'none'">
            <ion-item class="upload-instructions-container" button detail (click)="download()" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            Download this document and fill it out. If you are not able to download the document, check your browser's settings to allow popups
                            for this site.
                        </p>
                    </ion-text>
                </ion-label>
                <ion-button slot="end" class="download-button"> Download </ion-button>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            All documents must be under 4MB in size and in either PDF or JPEG format. Important: Please make sure all documents are in english
                            or have an english translation. Otherwise, they will be rejected by the Program.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 3</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you have reviewed your documents, you may upload them here.</p>
                    </ion-text>
                    <input type="file" id="file-input" accept="image/jpeg,.pdf,image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" />
                    <ion-button class="ion-text-center" expand="block" size="large">
                        <fa-icon [icon]="icons.desktop" slot="start"></fa-icon>
                        &nbsp;Browse Your Computer
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <!-- Immunization requirement -->
        <ion-list *ngIf="uploadIdentity === 'immune'">
            <ion-item class="upload-instructions-container" button (click)="downloadImmuneForm()" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            Download this document and fill it out. If you are not able to download the document, check your browser's settings to allow popups
                            for this site.
                        </p>
                    </ion-text>
                </ion-label>
                <ion-button slot="end" class="download-button"> Download </ion-button>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            All documents must be under 4MB in size and in either PDF or JPEG format. Important: Please make sure all documents are in english
                            or have an english translation. Otherwise, they will be rejected by the Program.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 3</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you have reviewed your documents, you may upload them here.</p>
                    </ion-text>
                    <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" />
                    <ion-button class="ion-text-center" expand="block" size="large">
                        <fa-icon [icon]="icons.desktop" slot="start"></fa-icon>
                        &nbsp;Browse Your Computer
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <!-- application requirement and no downloadable document -->
        <ion-list *ngIf="uploadIdentity === 'application' && url === 'none' && requiredSubmission">
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            All documents must be under 4MB in size and in either PDF or JPEG format. Important: Please make sure all documents are in english
                            or have an english translation. Otherwise, they will be rejected by the Program.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you have reviewed your documents, you may upload them here.</p>
                    </ion-text>
                    <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" />
                    <ion-button class="ion-text-center" expand="block" size="large" [disabled]="disable">
                        <fa-icon [icon]="icons.desktop" slot="start"></fa-icon>
                        &nbsp;Browse Your Computer
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <ion-list *ngIf="uploadIdentity === 'application' && url === 'none' && !requiredSubmission">
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            Review the description of this requirement carefully and make sure you understand what is required. If you have any questions,
                            please contact your coach to clarify.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>Once you understand what is required of you from this request, click "Acknowledged".</p>
                    </ion-text>
                    <!-- <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" /> -->
                    <ion-button class="ion-text-center" expand="block" size="large" (click)="acknowledgmentInit()">
                        <fa-icon [icon]="icons.acknowledge" slot="start"></fa-icon>
                        &nbsp;Acknowledged
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <!-- profile document and no downloadable document -->
        <ion-list *ngIf="uploadIdentity !== 'application' && uploadIdentity !== 'immune' && url === 'none'">
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 1</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            All documents must be under 4MB in size and in either PDF or JPEG format. Important: Please make sure all documents are in english
                            or have an english translation. Otherwise, they will be rejected by the Program.
                        </p>
                    </ion-text>
                </ion-label>
            </ion-item>
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-text>
                        <h2>Step 2</h2>
                    </ion-text>
                    <ion-text>
                        <p>
                            Once you have reviewed your documents, you may upload them here. <b>Note:</b> If you have multiple PDFs to upload, please upload one
                            PDF at a time.
                        </p>
                    </ion-text>
                    <input type="file" id="file-input" accept="image/jpeg,.pdf,application/pdf" (change)="upload($event.target.files)" />
                    <ion-button class="ion-text-center" expand="block" size="large" [disabled]="disable">
                        <fa-icon [icon]="icons.desktop" slot="start"></fa-icon>
                        &nbsp;Browse Your Computer
                    </ion-button>
                </ion-label>
            </ion-item>
        </ion-list>
        <ion-list *ngIf="prog.status != '' || prog.percent != 0">
            <ion-item class="upload-instructions-container" lines="none">
                <ion-label class="ion-text-wrap">
                    <ion-col size="12">
                        <ion-progress-bar value="{{ prog.percent }}"></ion-progress-bar>
                        <br />{{ prog.status }}
                    </ion-col>
                </ion-label>
            </ion-item>
        </ion-list>
    </ion-col>
</ion-grid>
