import { Component, Input, OnInit } from '@angular/core';
import { applicationSelectors } from '@app/core/ngrx/selectors';
import { ToolService } from '@app/core/services/tool/tool.service';
// ngrx actions
import { State } from '@core/ngrx/reducers';
import { faCommentAlt, faQuestionCircle, faComment } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { ModalController } from '@ionic/angular';
import { select, Store } from '@ngrx/store';


@Component({
  selector: 'app-help-contact',
  templateUrl: './help-contact.component.html',
  styleUrls: ['./help-contact.component.scss'],
})
export class SidebarHelpContactComponent implements OnInit {
  textcontent: any;
  @Input() header: string;
  @Input() title: string;
  @Input() item1: string;
  @Input() item2: string;
  @Input() item3: string;
  @Input() item4: string;
  @Input() icon: string;
  @Input() color: string;
  successEmail = 'success@amopportunities.org';
  whatsAppNumber = "+13122410640";

  icons: any = {
    envelope: faEnvelope,
    phone: faPhone,
    comment: faCommentAlt,
    question: faQuestionCircle,
    whatsApp: faComment
  };

  constructor(
    private modalCtrl: ModalController,
    private toolService: ToolService,
    private store: Store<State>
  ) {
    this.toolService.content.subscribe((textcontent: any) => this.textcontent = textcontent);
  }

  hasReserved$ = this.store.pipe(select(applicationSelectors.getHasReserved));

  ngOnInit() {/* empty */}

  close() {
    this.modalCtrl.dismiss();
  }

  toFAQ() {
    window.open(this.textcontent.basic.url.faq, '_blank');
  }

  toChat() {
    window.open(this.textcontent.basic.url.chat, '_blank');
  }
}
