import { Component, OnInit } from '@angular/core';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-partner-sidebar',
  templateUrl: './partner-sidebar.component.html',
  styleUrls: ['./partner-sidebar.component.scss'],
})
export class SidebarPartnershipsComponent implements OnInit {
  faCircle = faCircle;
  constructor(private modalCtrl: ModalController) { }

  ngOnInit() {/* empty */}

  dismiss() { this.modalCtrl.dismiss(); }

}
