import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
    selector: 'app-immunization-status-modal',
    templateUrl: './immunization-status-modal.component.html',
    styleUrls: ['./immunization-status-modal.component.scss'],
})
export class ImmunizationStatusModalComponent implements OnInit {
    @Input() statuses: any[];

    //icons
    faExclamationCircle = faExclamationCircle;
    faCheckCircle = faCheckCircle;

    constructor(private modalCtrl: ModalController) {}

    ngOnInit(): void {/* empty */}

    close() {
        this.modalCtrl.dismiss();
    }
}
