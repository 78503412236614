import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormService } from '@app/core/services/form/form.service';
import { ToolService } from '@app/core/services/tool/tool.service';
import { ModalController } from '@ionic/angular';
import { WarningService } from '@app/core/services/warning/warning.service';

@Component({
  selector: 'app-other-school',
  templateUrl: './other-school.component.html',
  styleUrls: ['./other-school.component.scss'],
})
export class OtherSchoolComponent implements OnInit {
  @Input() form: any;
  school = {};
  otherSchool_form: FormGroup;
  otherSchool_subscription = this.formService.otherSchoolForm().subscribe(
    (form: FormGroup) => this.otherSchool_form = form);
  constructor(
    private modalController: ModalController,
    private formService: FormService,
    public toolService: ToolService,
    private warning: WarningService
  ) { }

  ngOnInit() {/* empty */}

  dismiss() {
    this.modalController.dismiss({
        update: this.form,
    });
  }

  submitForm() {
    if (!this.otherSchool_form.valid) {
      const result = this.formService.invalidFields(this.otherSchool_form);
      const missingFields = result.map((field: any) => field.control).join(', ');
      this.warning.create(missingFields, `School form is missing the following fields: ${missingFields}`);
    } else {
      this.modalController.dismiss({
        school: this.otherSchool_form
      });
    }
  }

}
